import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";

import { SEO, Layout, Header, MissionBlurb, Member } from "../components";
import Content, { HTMLContent } from "../components/Content";
import { Section } from "../ui";
import { SectionTitle, Text } from "../ui/Texts";
import { Container, Column, Row, Wrapper } from "../ui/LayoutComponents";
import { linearGradients } from "../ui/variables";

import bgImage from "../img/header-images/Benevoles-tournoi.jpg";

export const NousConnaitrePageTemplate = ({
  title,
  whoAreWe,
  ourMissions,
  bureau,
  ourHistory,
  content,
  contentComponent,
}) => {
  const PageContent = contentComponent || Content;

  return (
    <>
      <SEO title={title} />
      <Header title={title} bgImage={bgImage} fallback={linearGradients.nousConnaitre} />
      <Section blueLight>
        <Container>
          <Wrapper>
            <SectionTitle marginB="2rem">{whoAreWe.title}</SectionTitle>
            <Text>{whoAreWe.text}</Text>
          </Wrapper>
        </Container>
      </Section>
      <Section>
        <Container>
          <Wrapper>
            <SectionTitle marginB="2rem">{ourMissions.title}</SectionTitle>
            <Column alignItems="flex-start">
              {ourMissions.missions.map((mission) => (
                <MissionBlurb
                key={mission.title}
                image={mission.image}
                title={mission.title}
                text={mission.text}
                />
                ))}
            </Column>
          </Wrapper>
        </Container>
      </Section>
      <Section blueLight>
        <Container>
          <Wrapper>
            <SectionTitle marginB="0">{bureau.title}</SectionTitle>
            <Row mobile flex={1} justifyContent="space-between" alignItems="flex-start">
              {bureau.members.map((member) => (
                <Member member={member} key={member.name} />
                ))}
            </Row>
          </Wrapper>
        </Container>
      </Section>
      <Section>
        <Container>
          <Wrapper>
            <Column>
              <SectionTitle marginB="2rem">{ourHistory.title}</SectionTitle>
              <PageContent className="markdown h3-grey" style={{ marginBottom: "3.5rem" }} content={content} />
            </Column>
          </Wrapper>
        </Container>
      </Section>
    </>
  );
};

NousConnaitrePageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  whoAreWe: PropTypes.object,
  ourMissions: PropTypes.object,
  bureau: PropTypes.object,
  ourHistory: PropTypes.object,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
};

const NousConnaitrePage = ({ data }) => {
  const { markdownRemark: post } = data;
  const { frontmatter } = post;

  return (
    <Layout>
      <NousConnaitrePageTemplate
        title={frontmatter.title}
        whoAreWe={frontmatter.whoAreWe}
        ourMissions={frontmatter.ourMissions}
        bureau={frontmatter.bureau}
        ourHistory={frontmatter.ourHistory}
        contentComponent={HTMLContent}
        content={post.html}
      />
    </Layout>
  );
};

NousConnaitrePage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default NousConnaitrePage;

export const nousConnaitrePageQuery = graphql`
  query NousConnaitrePage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        whoAreWe {
          title
          text
        }
        ourMissions {
          title
          missions {
            image {
              alt
              image {
                childImageSharp {
                  fluid(maxWidth: 300, quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            title
            text
          }
        }
        bureau {
          title
          members {
            image {
              alt
              image {
                childImageSharp {
                  fluid(maxWidth: 300, quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            name
            function
          }
        }
        ourHistory {
          title
        }
      }
    }
  }
`;
